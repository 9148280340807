<template>
  <v-container fluid>

    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 py-6' : 'px-1 py-1'">

      <v-alert
      outlined
      border="top"
      type="info"
      color="text"
      style="background-color:white !important;"
      >
          <span class="black--text">
            <h3 class="mb-2">Description for Request API Module</h3>
            <v-icon class="mr-2 mb-1">mdi-clipboard-text-outline</v-icon> This module allows Registered Users to request data according to the list of API Tables specified by JMG using the provided online API Sharing Request Form.<br>
            <v-icon class="mr-2 mb-1">mdi-email-outline</v-icon>  The system Administrator will receive a notification (via email in the Administration Module) and confirm the details of the Data Sharing Online Form (API) before the API link sent to the user's email.
          </span>
      
      </v-alert>

      <v-row :class="$vuetify.breakpoint.lgAndUp? 'px-6 py-6 d-flex' : 'px-1 py-1 d-flex'">
         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon class="mr-2 mb-1 text--text">mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Instruction</span>
        </v-tooltip>

        <v-btn class="mr-4 white--text" color="button" @click="dialogStationList = !dialogStationList">
          <v-icon class="mr-2 mb-1 white--text">mdi-format-list-bulleted</v-icon>
          Station List
        </v-btn>

        <v-btn color="button" class="white--text" @click="dialogAppForm = true">
          <v-icon class="mr-2 mb-1 white--text">mdi-file-document-edit-outline</v-icon>
          Application Form
        </v-btn>
      </v-row>
    
      <v-card>
        <v-card-title class="black--text px-6">
          <v-icon class="mr-3 text--text">mdi-format-list-bulleted</v-icon> Request API List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

        </v-card-title>

        <v-data-table
          :headers="headersList"
          :items="newList"
          :items-per-page="5"
          class="elevation-1 px-6 admin_table"
          :search="search"
          :loading="newList != [] ? false : true"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.status === 'APPROVE' ? 'green' : 'red' " dark>
             {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <template>
              <v-icon small class="mr-2 blue--text" @click="displayForm(item)"
                >mdi-pencil</v-icon
              >
            </template>
          </template>
        </v-data-table>
      </v-card>


      <!-- --------DIALOG DISPLAY FORM------------ -->

      <v-dialog 
          v-model="dialogDisplayForm" 
          max-width="80%"
          persistent
          scrollable
          >
            <template>
              <v-form>
                <v-card class="card-bg">
                  <v-card-title class="primary white--text justify-center dark card-header">
                    <v-icon class="mr-2 white--text">mdi-file-document-edit-outline</v-icon> <h3>Request Form</h3>
                  </v-card-title>

                  <v-card-text style="height: 80%" class="pa-3">
                    

                      <v-toolbar color="primary" dark dense>
                        <v-icon class="mr-2" >mdi-account-details</v-icon> Requestor Details
                      </v-toolbar>

                      <v-simple-table>
                        <template v-slot:default>

                          <colgroup>
                            <col span="1" style="width: 40%;">
                            <col span="1" style="width: 60%;">
                          </colgroup>
                          
                          <tbody>
                            <tr>
                              <td class="font-weight-bold">Full Name :</td>
                              <td>
                                <v-col cols="12" sm="12">
                                 {{ detailForm.position}}
                                </v-col>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Agency :</td>
                              <td>
                                <v-col cols="12" sm="12">
                                  {{ detailForm.purpose}}
                                </v-col>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Phone No :</td>
                              <td>
                                <v-col cols="12" sm="12">
                                  {{ detailForm.id}}
                                </v-col>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Email Address :</td>
                              <td>
                                <v-col cols="12" sm="12">
                                 {{ detailForm.email}}
                                </v-col>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Designation :</td>
                              <td>
                                <v-col cols="12" sm="12">
                                  {{ detailForm.position}}
                                </v-col>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-toolbar color="primary" dark dense>
                        <v-icon class="mr-2" >mdi-access-point-network</v-icon> API Requested Details
                      </v-toolbar>

                      <v-simple-table>
                        <template v-slot:default>

                          <colgroup>
                            <col span="1" style="width: 40%;">
                            <col span="1" style="width: 60%;">
                          </colgroup>
                          
                          <tbody>
                            <tr>
                              <td class="font-weight-bold">Tujuan kegunaan Web API (sila nyatakan) :</td>
                              <td>
                                <v-col cols="12" sm="12">
                              {{ detailForm.purpose}}
                                </v-col>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Adakah Web API ini akan di bawa ke Luar Negara? :</td>
                              <td>
                                <v-col cols="12" sm="12">
                                  <v-checkbox
                                    v-model="checkbox"
                                    :label="`Ya`"
                                  ></v-checkbox>
                                </v-col>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Surat Sokongan :</td>
                              <td>
                             
                                <v-col cols="12" sm="12" >
                                  <v-icon color="primary">
                                    mdi-file-link
                                  </v-icon>
                                  document 1.docx
                                </v-col>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-toolbar color="primary" dark dense>
                        <v-icon class="mr-2" >mdi-water-pump</v-icon> Air Quantity Telemetri Station
                      </v-toolbar>

                   
                        <v-data-table
                            :headers="headersList"
                            :items="newList"
                            :items-per-page="2"
                            class="elevation-1 py-6 pa-2 header-style"
                            :search="search"
                            :loading="newList != [] ? false : true"
                            
                            :disable-filtering="true"
                            :hide-default-footer="true"
                            
                          >
                            <template v-slot:item.status="{ item }">
                              <v-chip :color="item.status === 'APPROVE' ? 'green' : 'red' " dark>
                              {{ item.status }}
                              </v-chip>
                            </template>

                            <template v-slot:item.actions="{ item }">
                              <template>
                                <v-icon small class="mr-2 blue--text" @click="displayForm(item)"
                                  >mdi-pencil</v-icon
                                >
                              </template>
                            </template>
                          </v-data-table>


                      <v-toolbar color="primary" dark dense>
                        <v-icon class="mr-2" >mdi-water-pump</v-icon> Air Quality Telemetri Station
                      </v-toolbar>

                        <v-card>
                   
                        <v-data-table
                            :headers="headersList"
                            :items="newList"
                            :items-per-page="2"
                            class="elevation-1 py-6 pa-2 header-style"
                            :search="search"
                            :loading="newList != [] ? false : true"
                            :disable-filtering="true"
                            :hide-default-footer="true"
                            
                          >
                            <template v-slot:item.status="{ item }">
                              <v-chip :color="item.status === 'APPROVE' ? 'green' : 'red' " dark>
                              {{ item.status }}
                              </v-chip>
                            </template>

                            <template v-slot:item.actions="{ item }">
                              <template>
                                <v-icon small class="mr-2 blue--text" @click="displayForm(item)"
                                  >mdi-pencil</v-icon
                                >
                              </template>
                            </template>
                          </v-data-table>
                        </v-card>
                          
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="justify-center" style="background-color:white;">
                    <v-btn
                      @click="dialogDisplayForm = !dialogDisplayForm"
                      color="error" class="mr-5"
                    >
                      CANCEL
                    </v-btn>
                    <v-btn
                      @click="submitForm()"
                      color="button white--text"
                    >
                      SUBMIT
                    </v-btn>
                  </v-card-actions>

                </v-card>
              </v-form>
            </template>
          </v-dialog>
-
      <!---- Dialog Application Form ---->
      <v-dialog 
      v-model="dialogAppForm" 
      max-width="80%"
      persistent
      scrollable
      >
        <template>
          <v-form>
            <v-card class="card-bg">
              <v-card-title class="primary white--text justify-center dark card-header">
                <v-icon class="mr-2 white--text">mdi-file-document-edit-outline</v-icon> <h3>Application Form</h3>
              </v-card-title>

              <v-card-text style="height: 80%" class="pa-3">
                  <v-alert
                  outlined
                  border="top"
                  color="text"
                  style="background-color:white !important;"
                  >
                      <span class="black--text">
                        <h3 class="mb-2">Instruction: </h3>
                        <v-icon class="mr-2 mb-1">mdi-clipboard-text-outline</v-icon> Applicants from Government Agencies / Statutory Bodies shall be through the Director General / Director<br>
                        <v-icon class="mr-2 mb-1">mdi-email-outline</v-icon> Applicants from GLC / Private / NGOs must be through the Director / Project Manager<br>
                      </span>
                  
                  </v-alert>

                  <v-toolbar color="primary" dark dense>
                    <v-icon class="mr-2" >mdi-account-details</v-icon> Requestor Details
                  </v-toolbar>

                  <v-simple-table>
                    <template v-slot:default>

                      <colgroup>
                        <col span="1" style="width: 40%;">
                        <col span="1" style="width: 60%;">
                      </colgroup>
                      
                      <tbody>
                        <tr>
                          <td class="font-weight-bold">Full Name :</td>
                          <td>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="fullname"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">Agency :</td>
                          <td>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="agency"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">Phone No :</td>
                          <td>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="phoneNo"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">Email Address :</td>
                          <td>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="email"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">Designation :</td>
                          <td>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="designation"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-toolbar color="primary" dark dense>
                    <v-icon class="mr-2" >mdi-access-point-network</v-icon> API Requested Details
                  </v-toolbar>

                  <v-simple-table>
                    <template v-slot:default>

                      <colgroup>
                        <col span="1" style="width: 40%;">
                        <col span="1" style="width: 60%;">
                      </colgroup>
                      
                      <tbody>
                        <tr>
                          <td class="font-weight-bold">Tujuan kegunaan Web API (sila nyatakan) :</td>
                          <td>
                            <v-col cols="12" sm="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="purpose"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">Adakah Web API ini akan di bawa ke Luar Negara? :</td>
                          <td>
                            <v-col cols="12" sm="12">
                               <v-checkbox
                                v-model="api_agreement"
                                :label="`Ya`"
                              ></v-checkbox>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">Surat Sokongan :</td>
                          <td>
                            <v-col cols="12" sm="12">
                              <v-file-input
                                 label="File input"
                                 outlined
                                 dense
                                 v-model="supporting_doc"
                              ></v-file-input>
                            </v-col>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-toolbar color="primary" dark dense>
                    <v-icon class="mr-2" >mdi-water-pump</v-icon>Select Station
                  </v-toolbar>

                  <v-simple-table>
                    <template v-slot:default>

                      <colgroup>
                        <col span="1" style="width: 40%;">
                        <col span="1" style="width: 60%;">
                      </colgroup>
                      
                      <tbody>
                        <tr>
                          <td class="font-weight-bold">Station :</td>
                          <td>
                            <v-col cols="12" sm="12">
                             <v-select
                                :items="stationList"
                                outlined
                                dense
                                v-model="selected_station"
                                class="mt-2"
                              ></v-select>
                            </v-col>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center" style="background-color:white;">
                <v-btn
                  @click="cancelSubmit()"
                  color="error" class="mr-5"
                >
                  CANCEL
                </v-btn>
                <v-btn
                  @click="sendNewRequest()"
                  color="button white--text"
                >
                  SUBMIT
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-form>
        </template>
      </v-dialog>

      <!-- STATION LIST -->  
      <v-dialog 
      v-model="dialogStationList" 
      max-width="80%"
      persistent
      scrollable 
      >
        <v-form>
          <v-card class="card-bg">
            <v-card-title class="primary white--text justify-center dark card-header">
              <v-icon class="mr-2 white--text">mdi-file-document-edit-outline</v-icon> <h3>Station List</h3>
            </v-card-title>


            <v-card-text style="height: 80%">

              <v-data-table
                :headers="headersList"
                :items="newList"
                :items-per-page="5"
                class="elevation-1 admin_table my-4"
              ></v-data-table>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-end" style="background-color:white;">
              <v-btn
                @click="dialogStationList = !dialogStationList"
                color="error"
              >
                CLOSE
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-form>
      </v-dialog>
    
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import appMixins from "@/mixins/globalMixins";
// -- import something here --

export default {
  data: () => ({

    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/Mapviewer",
      },
      {
        text: "Request API List",
        disabled: true,
        href: "/requestAPI/requestApiList",
      },
    ],
    search: "",
    detailForm: {},
    statusForm: ["Lulus", "Tolak"],
    formStatus: "Lulus",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    tabs_Telemetry: null,

    headersList: [
      {
        text: "REFERENCE NO.",
        align: "start",
        sortable: false,
        value: "api",
      },
      { text: "EMAIL", value: "email" },
      { text: "REQUEST DATE & TIME", value: "requestdate" },
      { text: "STATUS", value: "status" },
      { text: "APPROVED DATE & TIME", value: "dateupdated" },
      { text: "FORM", value: "actions" },
    ],

    headersList2: [
      {
        text: "Agency",
        align: "start",
        sortable: false,
        value: "idUser",
      },
      { text: "Station ID", value: "email" },
      { text: "Location", value: "lastPwdChange" },
      { text: "Status", value: "dataValidationStatus" },
      { text: "Expired Date", value: "actions" },
      { text: "Remarks", value: "dataValidationStatus" },
    ],

    newFormDetails: {
      agency: "JAS",
      stationID: "CMS02",
      location: "SUNGAI PERAK",
      status: "Lulus",
      expiredDate: "07-02-2023",
      remarks: "Contaminated",
    },

    //---- Dialog ----
    dialogTeleList: false,
    dialogAppForm: false,
    dialogStationList: false,
    dialogDisplayForm: false,

    //---- Form ----
    fullname: "",
    agency: "",
    phoneNo: "",
    email: "",
    designation: "",
    purpose: "",
    api_agreement: "",
    supporting_doc: null,
    stationList: [],
    selected_station: "",

    newList: [],
    checkbox: true,
  }),

  mounted() {
    this.getAllUser();
  },

  methods: {
    getAllUser() {
      axios
        .get(this.globalUrl + `/miqims/apim/allapis?startreqdate=2023-01-01&endreqdate=2023-12-12`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.newList = response.data;
          console.log("here", this.newList);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    displayForm(item) {
      this.dialogDisplayForm = true;
      console.log("detailform", item);
      this.detailForm = item;
    },

    cancelSubmit(){
      this.dialogAppForm = false;
    },

    submitForm(){
      this.dialogAppForm = false;
      console.log("Submit form");
    },

    sendNewRequest() {

      let json = {
          "email": this.email,
          "api": "/apim/JMG01,/apim/JMG02",
          "position": this.designation,
          "purpose": this.purpose,
          "offshoreagency":  this.agency,
          "type": this.purpose,
          "dateexpire": "2023-10-10",
          "requestdoc": "https://www.wcofun.net/logo.gif",
          "approvedoc": "https://www.wcofun.net/logo.gif"
      };

      axios.post(this.globalUrl+`miqims/apim/addapi`, json, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          console.log(response.data);
          alert("Success");
          
        })
        .catch((error) => {
          console.log(error);
          alert("Failed")

        });




    }
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.pathModuls {
  padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a {
  color: black !important;
}

.background {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

.header-style > div > table > thead{
  background-color: whitesmoke !important;
}

</style>
